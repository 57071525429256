import React, { useState } from "react";
import "../assets/css/Gallery.css";

// Lista de imágenes estáticas
const imageList = [
  "IMG_8329.JPG",
  "IMG_8338.JPG",
  "IMG_8343.JPG",
  "IMG_8344.JPG",
  "IMG_8345.JPG",
  "IMG_8346.JPG",
  "IMG_8347.JPG",
  "IMG_8348.JPG",
  "IMG_8358.JPG",
  "IMG_8359.JPG",
  "IMG_8390.PNG",
  "IMG_8927.JPG",
  "Premios festival Freirina Live rock.JPG",
];

const MyBeginnings = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className="gallery-container">
      <h2>My Beginnings</h2>

      {/* Contenedor para el recuadro de video */}
      <div className="video-placeholder">
        <video width="100%" controls>
          <source
            src={require("../assets/img/mei-inizi/video-output-198BDABE-1D5F-40DC-A2B8-87C3E0EE299F.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Contenedor para la imagen seleccionada (solo en pantallas grandes) */}
      {selectedImage && (
        <div className="selected-image-container-large">
          <img
            src={require(`../assets/img/mei-inizi/${selectedImage}`)}
            alt="Selected"
            className="selected-image"
          />
        </div>
      )}

      {/* Contenedor de la grilla de imágenes */}
      <div className="image-grid">
        {imageList.map((image) => (
          <div
            key={image}
            className={`image-thumbnail ${
              selectedImage === image ? "selected-thumbnail-img" : ""
            }`}
            onClick={() => setSelectedImage(image)} // Al hacer click, se selecciona y agranda la imagen
          >
            <img
              src={require(`../assets/img/mei-inizi/${image}`)}
              alt={image}
              className="thumbnail-img"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyBeginnings;
