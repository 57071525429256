import React, { useState } from 'react';
import '../assets/css/AboutMe.css';
import decorativeImage from '../assets/img/dots.png';

const AboutMe = () => {
  const [language, setLanguage] = useState('en');

  const content = {
    en: {
      title: 'About Me',
      description: [
        `Born in the small port city of Huasco, in northern Chile, Gustavo Argandoña began singing and developing a passion for music from his early school years in his hometown, where he participated in various competitions and popular music festivals, earning praise and awards for his talent in singing and music.`,
        `However, his path before becoming a Renaissance and Baroque singer, which has gradually established him as one of the most promising countertenors of his generation, initially seemed oriented toward other musical horizons. He approached the study of guitar and formed a popular heavy metal band that received several recognitions in his country.`,
        `Also interested in the art of acting, while studying theater, he met Carlos Traverso, one of the most renowned musicians and choir directors in Chile. From that moment, after receiving singing lessons from him, he decided to dedicate himself entirely to singing and classical music. He graduated from the Universidad Alberto Hurtado in Santiago under the tutelage of Gonzalo Cuadra, a singer, musicologist, and Baroque specialist, who helped him understand the parallels between the rock music he composed and the Baroque style. This discovery further fascinated him with early music, and he developed the use of falsetto, something that came naturally to him and that he had already employed in some vocal passages with his rock group.`,
        `Still in Chile, he took his first steps as a countertenor, performing the role of the Swan in a presentation of Carl Orff’s Carmina Burana at the Universidad Alberto Hurtado. He then made his stage debut in the semi-opera King Arthur by Henry Purcell at the Gabriela Mistral Cultural Center (GAM) in Santiago, Chile. He also performed Johann Sebastian Bach’s Actus Tragicus and arias by Antonio Vivaldi at the Lutheran Church in Valparaíso, as well as Bach’s Cantata BWV 40 with the Universidad de Concepción Orchestra.`,
        `His path as a countertenor led him to position himself among the finalists of the international lyrical competition “Ciudad de Trujillo” in Peru, where he was also invited to a final concert at the Trujillo Municipal Theater. Later, he sang the aria “Cara sposa” from Handel’s Rinaldo with the University of Concepción Symphony Orchestra in Chile, and with the same orchestra, he performed Antonio Vivaldi’s cantata Nisi Dominus, which was streamed online.`,
        `In 2021, he decided to move to Europe to continue his studies and chose Italy to refine his Baroque singing at the Conservatorio Antonio Vivaldi in Alessandria, under the direction of Mirko Guadagnini. Meanwhile, his training was enriched through seminars and masterclasses with great singers and musicians specializing in Baroque music, such as Sonia Prina, Xavier Sabata, Antonio Greco, Stefano Aresi, Fabio Biondi, Simone Ori, Christian Senn, and Manuela Custer.`,
        `Shortly after arriving in Italy, he reached the semifinals of the Traetta Opera Festival Competition in Bitonto and later won the “Giuseppe Di Stefano” International Competition in Trapani, obtaining first prize in the special “Voci di cristallo” section dedicated to countertenors. Thanks to this victory, he made his solo debut in Italy at the Luglio Musicale.`
      ]
    },
    it: {
      title: 'Su di me',
      description: [
        `Nato nella piccola città portuale di Huasco, al nord del Cile, Gustavo Argandoña, fin da piccolo, ha cominciato a cantare e ad appassionarsi alla musica dai primi anni di scuola nella sua città di origine, dove si è esibito in diverse competizioni e festival di musica popolare, guadagnandosi apprezzamenti e premi per la sua predisposizione al canto e alla musica.`,
        `Tuttavia il suo percorso, prima di approdare al canto rinascimentale e barocco che l’ha visto progressivamente imporsi fra più promettenti controtenori della sua generazione, sembrava inizialmente orientato verso altri orizzonti musicali, avvicinandosi allo studio della chitarra e creando una popolare band di heavy metal che ha ottenuto diversi riconoscimenti nel suo Paese.`,
        `Interessato anche all’arte attoriale, mentre studiava recitazione ha conosciuto Carlos Traverso, uno dei più noti musicisti e direttori di coro del Cile e, da quel momento, dopo aver preso da lui lezioni di canto, ha deciso di dedicarsi totalmente al canto e alla musica classica laureandosi all’Università Alberto Hurtado a Santiago sotto la guida di Gonzalo Cuadra, musicologo e specialista in barocco, grazie al quale ha avuto coscienza dei parallelismi esistenti fra la musica rock che componeva e lo stile barocco, rimanendo sempre più affascinato dalla musica antica e sviluppando così l’utilizzo del falsetto che gli veniva naturale e già impiegava in alcuni passaggi vocali con il suo gruppo rock.`,
        `Sempre in Cile, ha mosso i primi passi della sua carriera di controtenore, prendendo parte, nel ruolo del Cigno, ad una esecuzione dei Carmina Burana di Carl Orff tenutasi presso la Università Alberto Hurtado ed ha poi debuttato sulle scene nella rappresentazione della semi-opera King Arthur di Henry Purcell tenutasi presso il Centro Culturale Gabriela Mistral GAM (Santiago del Cile). Ha poi eseguito “Actus Tragicus” di Johan Sebastian Bach e arie di Antonio Vivaldi nella chiesa luterana di Valparaiso e la Cantata BWV 40 di Bach con l’Orchestra dell’Università di Concepción.`,
        `Il cammino da controtenore l’ha visto posizionarsi tra i finalisti del concorso lirico internazionale “Ciudad de Trujillo” in Perù, invitato anche a un concerto finale tenutosi presso il teatro comunale di Trujillo. Ha poi cantato l’aria “Cara sposa” dal Rinaldo di Händel con l’Orchestra Sinfonica della Università di Concepción in Cile e, con la stessa orchestra, ha proposto la cantata Nisi Dominus di Antonio Vivaldi, trasmessa online.`,
        `Nel 2021 ha deciso di trasferirsi in Europa per proseguire gli studi ed ha scelto l’Italia per perfezionarsi in canto barocco presso il Conservatorio Antonio Vivaldi di Alessandria, sotto la guida di Mirko Guadagnini.`,
        `Nel frattempo la sua formazione si è affinata attraverso seminari e masterclass con grandi cantanti e musicisti specializzati nel barocco, tra cui Sonia Prina, Xavier Sabata, Antonio Greco, Stefano Aresi, Fabio Biondi, Simone Ori, Christian Senn e Manuela Custer.`,
        `Appena giunto in Italia si è classificato semifinalista al Concorso Traetta Opera Festival di Bitonto ed ha poi vinto il Concorso Internazionale “Giuseppe Di Stefano” di Trapani, aggiudicandosi il primo premio nella sezione speciale “Voci di cristallo”, dedicato ai controtenori. Grazie a questa vittoria, ha debuttato come solista in Italia al Luglio Musicale.`
      ]
    }
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };


  return (
    <div className="p-5 about-me">
      <div className="about-me-content">
        <div className="language-switcher">
          <button className="btn btn-outline-secondary me-2" onClick={() => changeLanguage('en')}>English</button>
          <button className="btn btn-outline-secondary" onClick={() => changeLanguage('it')}>Italiano</button>
        </div>
        <h2 className="mb-3">{content[language].title}</h2>
        {content[language].description.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        {/* <div className="image-container mt-4">
          <img src={decorativeImage} alt="Decorative" className="decorative-image" />
        </div> */}
      </div>
    </div>
  );
};

export default AboutMe;
