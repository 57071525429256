import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/Gallery.css';

const PortraitsGallery = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/images/portraits');
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching portrait images:', error);
      }
    };

    fetchImages();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  return (
    <div className="gallery-container">
      <h2>Portraits</h2>
      
      {/* Contenedor para la imagen seleccionada */}
      {selectedImage && (
        <div className="selected-image-container">
          <img
            src={`data:image/jpeg;base64,${selectedImage.route}`}
            alt={selectedImage.subtitle}
            className="selected-image"
          />
          <div className="selected-image-caption">
            <h5>{selectedImage.subtitle}</h5>
            <p>{selectedImage.credits}</p>
            <p>{formatDate(selectedImage.date)}</p>
          </div>
        </div>
      )}

      {/* Contenedor de la grilla de imágenes */}
      <div className="image-grid">
        {images.map((image) => (
          <div
            key={image.id}
            className="image-thumbnail"
            onClick={() => setSelectedImage(image)} // Al hacer click, se selecciona la imagen
          >
            <img
              src={`data:image/jpeg;base64,${image.route}`}
              alt={image.subtitle}
              className="thumbnail-img"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortraitsGallery;
